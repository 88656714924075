
import React, { Component, useState } from 'react';
import { Col } from 'reactstrap';
import axios from "axios";
import Image from "gatsby-image"
import styled from 'styled-components'
import Box from '../components/box'
const config = require('../../data/siteConfig')


const ProcedureImage = styled(Image)`
  border-radius: 10px;
  max-width: 200px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`
const BoxWrapper = styled(Box)`
text-align: center !important;
height: unset;
padding-left: unset;
border-left: 0px;
`



class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
          asociacion:null,
          link: props.link,
          imageMakeProcedure:props.imageMakeProcedure,
          procedureListId: {
            data:  [],
          },
          idList:{
            data:  props.procedureListId,
          },
          look: props.look,
          procedureCode: props.procedureCode,
          serverUrl:config.serverUrl

        };
 
        this.handleChange = this.handleChange.bind(this);
        this.getList()
  
      }
    
      handleChange(e) {
        this.setState({ asociacion: e.target.value });
      }

    getList() {
        const { procedureCode, idList,serverUrl } = this.state
        var headers = {
            'Content-Type': 'application/json' 
        }
        axios({
            method: "GET",
            url: serverUrl+"professionalGroup/proceduretype/code/" + procedureCode,
            headers: headers

        })
            .then(r => {
                    this.setState({
                        procedureListId: r.data
                      });
            })
            .catch(r => {
                console.log(r);
                this.setState({
                    procedureListId: idList
                  });
            });
    }

    render(props) {
        const { link, imageMakeProcedure, procedureListId, look } = this.state
        return (
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
                <Col>
                    <select className="selectClass" value={this.state.asociacion} onChange={this.handleChange}>
                        <option disabled selected>Seleccione un tipo para inciar el tramite</option>
                      
                        {procedureListId.data.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                    </select>

                    {this.state.asociacion &&
                        <a href={link + this.state.asociacion+"/"+look} rel="noopener noreferrer">
                            <BoxWrapper>
                                <ProcedureImage className="mt-3" fluid={imageMakeProcedure} />
                                <h6 className="mt-3">Iniciar trámite</h6>
                            </BoxWrapper>
                        </a>
                    }
                </Col>
            </Col>
        );
    }
    }

    export default Select;