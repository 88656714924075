import React from 'react'
import { graphql } from 'gatsby'
import Breadcrumb from "../components/breadcrumb"
import styled from 'styled-components'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Image from "gatsby-image"
import Link from '../components/link'
import Box from '../components/box'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import {
  isAndroid,
  isIOS,
  isMobile,
  isTablet,
} from "react-device-detect";
import navigation from '../data/navigation';
import { Helmet } from "react-helmet"
import Select from '../components/select'



const ProcedureImage = styled(Image)`
  border-radius: 10px;
  max-width: 200px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`
const BoxWrapper = styled(Box)`
text-align: center !important;
height: unset;
padding-left: unset;
border-left: 0px;
`
let MakeProcedure = ({ title, image, link, procedureId, locationText }) => (
  <Col>
    <Link to={link}>
      <BoxWrapper>
        <ProcedureImage className="mt-3" fluid={image} />
        <h6 className="mt-3">{title}</h6>
        {locationText && <div className="locationText text-muted">{locationText}</div>}
      </BoxWrapper>
    </Link>
  </Col>
)

export const query = graphql`
query ProcedureQuery($slug: String!) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    frontmatter {
      title
      subTitle
      description
      procedureId
      procedureIdMat
      procedureLook
      prodecureCipe
      procedureNot
      location
      locationText
      fileName
      procedureIdLeg
      claims
      procedureIdNat
      procedureCombo
      procedureCode
      procedureListId{
        value
        label
      }
      attachments {
        publicURL
      }
    }
  }
}
`;

function portal(procedureId, procedureLook, link, procedureCipe, procedureNot, isAndroid, isIOS, playStore, appleStore) {
  let linkCasillero= navigation[0].url;
  if(procedureNot){
    if(isIOS){
      linkCasillero= appleStore;
    }
    if(isAndroid){
      linkCasillero= playStore;
    }
    return linkCasillero;
  }
  var result =linkCasillero + link + procedureId + "/" + procedureLook;
  if (procedureCipe) {
    result= linkCasillero + link + "cipe/" + procedureId + "/" + procedureLook;
  }

  const intent = "intent:#Intent;action=com.gpsl.portalpuntano.launchfrombrowser;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.id_tramite="
  const look = "S.look="
  const end = ";end"
  if (isAndroid) {
    result = intent + procedureId + end;
    if (procedureLook === "BLACK") {
      result = intent + procedureId + ";" + look + "black" + end
    }
  }
  return result
}

function toStore(path) {
  if (isMobile) {
    if (typeof window !== `undefined`) {
      window.location.replace(path);
      setTimeout(function appNotInstalled() {
        window.location.replace("https://play.google.com/store/apps/details?id=com.gpsl.st.portalpuntano");
      }, 2000);
    }
  } else {
    window.location.replace(path);
  }
}

function mobile(procedureId, procedureLook, link) {
  const path = portal(procedureId, procedureLook, link)
  if (isMobile) {
    return toStore(path)
  } else {
    return path
  }

}

export default function Procedure(props) {
  let linkCasillero= navigation[0].url;
  const { locationProcedureIcon, makeProcedureIcon, siteTitle, playStore, appleStore, rpiLicense, downloadIcon } = useSiteMetadata()
  let android= isAndroid;
  let ios= isIOS;
  const imageLocation = useSiteImages(locationProcedureIcon.imageName).fluid;
  const imageDownload = useSiteImages(downloadIcon.imageName).fluid;
  const imageMakeProcedure = useSiteImages(makeProcedureIcon.imageName).fluid;
  const imageLicense = useSiteImages(rpiLicense.imageName).fluid;
  const procedure = props.data.markdownRemark;

  const {claims, procedureCombo, title, subTitle, description, location, locationText, procedureId, procedureIdMat,procedureLook, fileName, attachments, procedureIdLaw, 
    prodecureCipe,  procedureNot, procedureIdNat, procedureIdLeg,procedureListId, procedureCode } = procedure.frontmatter;

  const path = portal(procedureId, procedureLook, makeProcedureIcon.link, prodecureCipe, procedureNot, android, ios, playStore, appleStore);
  const pathLaw = portal(procedureIdLaw, procedureLook, makeProcedureIcon.link, prodecureCipe,procedureNot, android, ios,playStore, appleStore);
  
  const uptitle=title+" | "+siteTitle


  return (
    <Layout>
           <Helmet title={uptitle}>
          <meta charSet="utf-8" />
        </Helmet>
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col sm="12" md={{ size: 8 }}>
            <h1 className="display-4">{title}</h1>
            <h3 className="text-muted">{subTitle}</h3>
            <h2>Descripción</h2>
            {description && <p>{description}</p>}
            <div dangerouslySetInnerHTML={{ __html: procedure.html }} />
            {fileName &&
              <div>
                <h2>Documentación</h2>
                <p><a href={attachments[0].publicURL} download>
                  {fileName}  </a></p>
              </div>}
            {locationText &&
              <div className="personal_procedure">
                <h4>Datos Tramite personal</h4>
                <p>Presentando toda la documentación requerida en {locationText}</p>
              </div>
            }

          </Col>
        </Row>
        <Row className="justify-content-center">
          {!isIOS && procedureIdMat &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <Col>
              <a href={rpiLicense.link} target="_blank" rel="noopener noreferrer">
                  <BoxWrapper>
                    <ProcedureImage className="mt-3" fluid={imageLicense} />
                    <h6 className="mt-3">Iniciar trámite </h6>
                  </BoxWrapper>
                </a>
              </Col>
    
            </Col>}
          {!isIOS && procedureId &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <Col>
              <a href={path}  rel="noopener noreferrer">
                  <BoxWrapper>
                  <ProcedureImage className="mt-3" fluid={imageMakeProcedure} />
                  <h6 className="mt-3">{makeProcedureIcon.title}
                  {procedureIdLaw && makeProcedureIcon.notary}
                  </h6>
                  </BoxWrapper>
                </a>
              </Col>
    
            </Col>}
          {!isIOS && procedureIdLaw &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <Col>
              <a href={pathLaw}  rel="noopener noreferrer">
                  <BoxWrapper>
                    <ProcedureImage className="mt-3" fluid={imageMakeProcedure} />
                    <h6 className="mt-3">{makeProcedureIcon.title + makeProcedureIcon.law }</h6>
                  </BoxWrapper>
                </a>
              </Col>
    
            </Col>}
            {!isIOS && procedureIdNat &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <Col>
              <a href={linkCasillero+makeProcedureIcon.link+procedureIdNat+ "/" +procedureLook}  rel="noopener noreferrer">
                  <BoxWrapper>
                    <ProcedureImage className="mt-3" fluid={imageMakeProcedure} />
                    <h6 className="mt-3">Persona Física</h6>
                  </BoxWrapper>
                </a>
              </Col>
    
            </Col>}
            {!isIOS && procedureIdLeg &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <Col>
              <a href={linkCasillero+makeProcedureIcon.link+procedureIdLeg+"/"+procedureLook}  rel="noopener noreferrer">
                  <BoxWrapper>
                    <ProcedureImage className="mt-3" fluid={imageMakeProcedure} />
                    <h6 className="mt-3">Persona Jurídica</h6>
                  </BoxWrapper>
                </a>
              </Col>
    
            </Col>}
            {!isIOS && claims &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <Col>
              <a href="/installApp"  rel="noopener noreferrer">
                  <BoxWrapper>
                    <ProcedureImage className="mt-3" fluid={imageDownload} />
                    <h6 className="mt-3">Descarga la aplicación</h6>
                  </BoxWrapper>
                </a>
              </Col>
    
            </Col>}
            {!isIOS && procedureCombo &&
           
                    <Select link={linkCasillero+makeProcedureIcon.link} imageMakeProcedure={imageMakeProcedure} procedureListId={procedureListId}
                    look={procedureLook} procedureCode={procedureCode}/>
            }
          {location &&
            <Col className="text-justify" sm="6" md={{ size: 4 }}>
              <MakeProcedure link={locationProcedureIcon.link + location} title={locationProcedureIcon.title} image={imageLocation} />
            </Col>}
        </Row>
      </Container>
      <Breadcrumb pageContext={props.pageContext} label={title} />
    </Layout>
  );
}